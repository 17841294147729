<template>
    <div class="account-page" v-loading="loading">
        <h2>账户总览</h2>
        <div class="account-content">
            <h3>账号信息</h3>
            <p>姓名：{{ info.truename }}</p>
            <p>登陆账号：{{ info.username }}</p>
            <h3>提现信息</h3>
            <p class="b">余额：{{ info.apply_money }}元</p>
            <p>冻结期限：订单付款成功，{{ info.freeze_day }}天后自动到账</p>
            <p>手续费：{{ info.fee_ratio }}</p>
            <p>提现方式：{{ cashType[info.cash_type] || '' }}</p>
            <p>提现账户类型：{{ cashUserType[info.cash_user_type] || '' }}</p>
            <p v-if="info.cash_user_type == 1">商户号：{{ info.cash_user_id }}</p>
            <div v-else class="line">
                <div class="m-t-12">提现账户：</div>
                <div>
                    <p>用户id：{{ info.cash_user_info?.id || '' }}</p>
                    <p>用户昵称：{{ info.cash_user_info?.nickname || '' }}</p>
                    <p>授权手机号：{{ info.cash_user_info?.phone || '' }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import http from '@/assets/js/http';

const cashType = ref({
    1: '微信',
});

const cashUserType = ref({
    1: '商户号',
    2: '个人微信',
});


const info = ref({
    truename: '',
    username: '',
    apply_money: 0,
});
const loading = ref(false);
const getInfo = () => {
    loading.value = true;
    http.get(`/agent/Member/overview`)
        .then((res) => {
            info.value = res;
        })
        .finally(() => {
            loading.value = false;
        });
};
getInfo();
</script>

<style lang="scss" scoped>
.account-page {
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    h2 {
        margin: 0;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--el-border-color-light);
    }
    .account-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 40px 0;

        h3 {
            width: 55%;
        }

        p {
            width: 40%;
            min-width: 200px;
            padding: 10px 0;
            &.b {
                font-weight: bold;
            }
        }

        .line {
            display: flex;
            min-width: 40%;
        }
    }
}
</style>
